/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  CheckboxField,
  Flex,
  Text,
  TextAreaField,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function ContactUs(props) {
  const { devCard, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        ContactUsTitle: {},
        Title38465735: {},
        "*38465736": {},
        TitleContent38465734: {},
        TextField38465846: {},
        Name: {},
        Title38465740: {},
        "*38465741": {},
        TitleContent38465739: {},
        TextField38465839: {},
        officeName: {},
        Title38465746: {},
        "*38465747": {},
        TitleContent38465745: {},
        TextField38465853: {},
        Mail: {},
        Title38465752: {},
        "*38465753": {},
        TitleContent38465751: {},
        TextField38465872: {},
        PhoneNumber: {},
        Title38465758: {},
        "*38465759": {},
        TitleContent38465757: {},
        TextAreaField: {},
        Content: {},
        InputContent: {},
        CheckboxField: {},
        Text38465765: {},
        Text38465766: {},
        AgreementText: {},
        SendButton: {},
        SendMessage: {},
        SendButtonContainer: {},
        ContactUs: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        ContactUsTitle: {},
        Title38465735: {},
        "*38465736": {},
        TitleContent38465734: {},
        TextField38465846: {},
        Name: {},
        Title38465740: {},
        "*38465741": {},
        TitleContent38465739: {},
        TextField38465839: {},
        officeName: {},
        Title38465746: {},
        "*38465747": {},
        TitleContent38465745: {},
        TextField38465853: {},
        Mail: {},
        Title38465752: {},
        "*38465753": {},
        TitleContent38465751: {},
        TextField38465872: {},
        PhoneNumber: {},
        Title38465758: {},
        "*38465759": {},
        TitleContent38465757: {},
        TextAreaField: {},
        Content: {},
        InputContent: { width: "unset", alignSelf: "stretch" },
        CheckboxField: {},
        Text38465765: {},
        Text38465766: {},
        AgreementText: {},
        SendButton: {},
        SendMessage: {},
        SendButtonContainer: {},
        ContactUs: { width: "375px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="30px"
      direction="column"
      width="745px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      border="0px SOLID rgba(255,255,255,1)"
      padding="30px 0px 30px 0px"
      backgroundColor="rgba(249,249,249,1)"
      display="flex"
      {...getOverrideProps(overrides, "ContactUs")}
      {...rest}
    >
      <Text
        fontFamily="Noto Sans JP"
        fontSize="25px"
        fontWeight="700"
        color="rgba(41,41,41,1)"
        lineHeight="36.19999694824219px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="お問い合わせ"
        {...getOverrideProps(overrides, "ContactUsTitle")}
      ></Text>
      <Flex
        gap="20px"
        direction="column"
        width="745px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 20px 0px 20px"
        display="flex"
        textAlign="left"
        {...getOverrideProps(overrides, "InputContent")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Name")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "TitleContent38465734")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="お名前"
              {...getOverrideProps(overrides, "Title38465735")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(186,0,0,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="*"
              {...getOverrideProps(overrides, "*38465736")}
            ></Text>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            placeholder="お名前"
            shrink="0"
            alignSelf="stretch"
            backgroundColor="rgba(255,255,255,1)"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextField38465846")}
          ></TextField>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "officeName")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "TitleContent38465739")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="会社名"
              {...getOverrideProps(overrides, "Title38465740")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(186,0,0,1)"
              lineHeight="16px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="*"
              {...getOverrideProps(overrides, "*38465741")}
            ></Text>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            placeholder="会社名"
            shrink="0"
            alignSelf="stretch"
            backgroundColor="rgba(255,255,255,1)"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextField38465839")}
          ></TextField>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Mail")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "TitleContent38465745")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="メールアドレス"
              {...getOverrideProps(overrides, "Title38465746")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(186,0,0,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="*"
              {...getOverrideProps(overrides, "*38465747")}
            ></Text>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            placeholder="example@example.com"
            shrink="0"
            alignSelf="stretch"
            backgroundColor="rgba(255,255,255,1)"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextField38465853")}
          ></TextField>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "PhoneNumber")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "TitleContent38465751")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="電話番号"
              {...getOverrideProps(overrides, "Title38465752")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(186,0,0,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="*"
              {...getOverrideProps(overrides, "*38465753")}
            ></Text>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            placeholder="000-0000-0000"
            shrink="0"
            alignSelf="stretch"
            backgroundColor="rgba(255,255,255,1)"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextField38465872")}
          ></TextField>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "TitleContent38465757")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="お問い合わせ内容"
              {...getOverrideProps(overrides, "Title38465758")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(186,0,0,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="*"
              {...getOverrideProps(overrides, "*38465759")}
            ></Text>
          </Flex>
          <TextAreaField
            width="unset"
            height="unset"
            placeholder="お問い合わせ内容をお書きください"
            shrink="0"
            alignSelf="stretch"
            backgroundColor="rgba(255,255,255,1)"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextAreaField")}
          ></TextAreaField>
        </Flex>
      </Flex>
      <Flex
        gap="5px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "AgreementText")}
      >
        <CheckboxField
          width="unset"
          height="unset"
          shrink="0"
          borderRadius="4px"
          backgroundColor="rgba(255,255,255,1)"
          size="default"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "CheckboxField")}
        ></CheckboxField>
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="500"
          color="rgba(109,196,102,1)"
          lineHeight="16px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="個人情報取り扱い"
          {...getOverrideProps(overrides, "Text38465765")}
        ></Text>
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="500"
          color="rgba(41,41,41,1)"
          lineHeight="16px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="に同意します。"
          {...getOverrideProps(overrides, "Text38465766")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0)"
        borderRadius="30px"
        padding="7px 15px 7px 15px"
        backgroundColor="rgba(0,0,0,0)"
        display="flex"
        {...getOverrideProps(overrides, "SendButtonContainer")}
      >
        <Button
          width="unset"
          height="unset"
          borderRadius="30px"
          shrink="0"
          backgroundColor="rgba(109,196,102,1)"
          size="default"
          isDisabled={false}
          variation="primary"
          children="送信する"
          {...getOverrideProps(overrides, "SendButton")}
        ></Button>
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="500"
          color="rgba(109,196,102,1)"
          lineHeight="16px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="送信が完了しました！"
          {...getOverrideProps(overrides, "SendMessage")}
        ></Text>
      </Flex>
    </Flex>
  );
}
