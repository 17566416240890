import React, { useEffect } from "react";
import Introduction from "./Introduction"; // Introductionコンポーネントをインポート

// 画像をインポート
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { 
      images[item.replace('./', '')] = r(item); 
    });
    return images;
}
  
const images = importAll(require.context('../img/', false, /\.png$/));

export default function ModifyIntroduction() {

  return (
    <Introduction
      id="introduction"
      overrides={{
        Introduction: {
          width: "100%",
        },
        Component1: {
          src: images["component1.png"],
        },
        Component2: {
          src: images["component2.png"],
        },
        "Component2-1": {
          src: images["component2.png"],
        },
        Component3: {
          src: images["component3.png"],
        },
        TextOrange: {
          // テキストの中に改行タグやリンクを含めてchildrenとして渡す
          children: (
            <>
              <a
                href="https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/delivering-large-scale-it-projects-on-time-on-budget-and-on-value"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "rgba(255,110,33,1)" }}
              >
                マッキンゼーのレポート
              </a>
              によれば、大規模開発は、予算を45%、期間を7%超過しながら、予測された価値の56%しか提供しません。<br />
              同レポートによれば、有効な処方箋は、短い納品サイクルと厳格な品質チェックと言われています。<br />
              また、
              <a
                href="https://ssaits.jp/promapedia/articles/20210927.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "rgba(255,110,33,1)" }}
              >
                国内の調査
              </a>
              によれば、品質と予算と納期をすべて満たしたプロジェクトは10%ともいわれています。<br />
              弊社は、内部開発のAIにより、短納期のサイクルと品質保証を実現しています。<br />
              その結果、これまでお取引いただいたすべてのクライアント様から、継続発注をいただいております。
            </>
          ),
        },
        Text38617276: {
            children: (
                <>
                    <div>
                        弊社は、10年以上にわたり多岐にわたる業界でシステム開発に携わってきた実績があります。<br />
                        大手上場企業様を中心に、長期にわたってお取引していただいています。<br />
                        これまでお取引いただいたすべてのクライアントから、継続発注をいただいております。<br />
                        最も得意とするのは、要件が定まっていない状態から整理して手早くシステムを構築することです。<br />
                        売れる/使われるシステムの開発に取り組ませていただければと思っています。<br />
                        特に、集英社様とのお取り組みで作ったゆるっとジャンプ＋は、規模の違いはありますが、通常ソーシャルゲームの開発が2-3年かかるところを4ヶ月で企画・開発・納品しました。
                    </div>
                </>
            )
        },
        Text38617281: {
            children: (
                <>
                    <div>
                        弊社は40000人に1人の人材を採用しています。<br />
                        自社開発ゲーム「ミリオンダウト」の累計ダウンロード数は40万超になります。<br />
                        その40万人の中からトップランカーの称号である「Jokerクラス」の到達者約500名の中から、さらに厳選した人材を10人、これまで採用してきました。<br />
                        結果として、囲碁のプロ棋士、東大出身のキャリア官僚、Mensa会員からペン回し日本一の人物まで、多様性に富む人材を採用してきました。採用後は、ほぼ全員エンジニアとして活躍しております。<br />
                        ミリオンダウトは、論理的な思考力、不運への耐性、自己責任への理解を強く要求されることを目指してつくられたゲームです。<br />
                        これらの素養は、ロジカルに思考する能力だけでなく、ビジネス上で求められるリスクテイクとリスクマネジメントに必要な能力であると考えています。<br />
                        結果として、納期遅延率0%を実現でき、お客様からの高い評価を頂くことができました。
                    </div>
                </>
            )
        },
        Text38617286: {
            children: (
                <>
                    <div>
                        生成AIのお取り組みにあたり、弊社が重視しているのがUXと利用ガイドラインの制定です。<br />
                        ・UXについて<br />
                        多くの人にとって、自由入力欄でAIを活用することに慣れていません。<br />
                        使いやすさを重視することで、凄いけど誰も使わないツールではなく、多くの人に使われることを目指したプロダクトづくりを心がけています。<br />
                        ・生成AIの利用ガイドラインについて<br />
                        多くの企業にとって、生成AIのリスクの把握や社内での利用上のガイドラインの設計が急務であると認識しています。<br />
                        他社動向を交えながら、ガイドラインのお手伝いをさせていただきます。
                    </div>
                </>
            )
        }
      }}
    />
  );
}
