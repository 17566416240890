import React, { useState } from "react";
import ContactUs from "./ContactUs"; // 既存のContactUsコンポーネントをインポート

export default function ContactUsOverride(props) {
  const [isSubmitted, setIsSubmitted] = useState(false); // 送信完了メッセージ用の状態
  const [errorMessage, setErrorMessage] = useState(""); // エラーメッセージ用の状態

  // 送信ボタンをクリックしたときのハンドラ
  const handleSubmit = async (event) => {
    event.preventDefault(); // デフォルトのフォーム送信を防ぐ
  
    const form = event.target;
    const data = new FormData(form);
  
    try {
      // Googleフォームにデータを送信
      const response = await fetch(form.action, {
        method: form.method,
        body: data,
        mode: 'no-cors', // クロスオリジン制限回避
      });
  
      // レスポンスが成功したか確認する（no-corsモードでは直接ステータスを確認できないため、エラーがなければ成功とみなす）
      if (response) {
        // 送信完了メッセージを表示
        setIsSubmitted(true);
        setErrorMessage(""); // エラーメッセージをクリア
      } else {
        // エラーが発生した場合、エラーメッセージを表示
        setIsSubmitted(false);
        setErrorMessage("送信に失敗しました。もう一度お試しください。"); // エラーメッセージを設定
      }
    } catch (error) {
      // ネットワークエラーの場合、エラーメッセージを表示
      setIsSubmitted(false);
      setErrorMessage("ネットワークエラーが発生しました。接続を確認して再度送信してください。"); // ネットワークエラーのメッセージを設定
    }
  };  

  const overrides = {
    ContactUs: {
      as: "form", 
      action: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSf31_azpcWuxKWax6apf3Ip7Mz-hJ56XEF3V4iphrQ_FkFUng/formResponse", 
      method: "post",
      onSubmit: handleSubmit, // 送信時にhandleSubmitを実行
      width: "100%"
    },
    TextField38465846: { 
      name: "entry.805058004", // Googleフォームのname属性に対応するもの
      placeholder: "お名前",
      required: true, // 必須フィールドにする
      type: "text" // 適切なtypeを指定（お名前はtext）
    },
    TextField38465839: { 
      name: "entry.1197073352", // Googleフォームのname属性に対応するもの
      placeholder: "会社名", 
      required: false, // 会社名は任意フィールド
      type: "text" // 適切なtypeを指定（会社名はtext）
    },
    TextField38465853: { 
      name: "entry.332287486", // Googleフォームのname属性に対応するもの
      placeholder: "example@example.com", 
      required: true, // 必須フィールド
      type: "email" // メールアドレス用のtype
    },
    TextField38465872: { 
      name: "entry.554017064", // Googleフォームのname属性に対応するもの
      placeholder: "000-0000-0000", 
      required: true, // 必須フィールド
      type: "tel", // 電話番号用のtype
      pattern: "[0-9]{2,4}-?[0-9]{3,4}-?[0-9]{3,4}",
      title: "電話番号は半角数字を入力してください。ハイフン(-)は任意です"
    },
    TextAreaField: { 
      name: "entry.1037937381", // Googleフォームのname属性に対応するもの
      placeholder: "お問い合わせ内容", 
      required: true, // 必須フィールド
      as: "textarea" // テキストエリアとして設定
    },
    CheckboxField: {
      name: "agreement",
      label: (
        <>
          <a
            href="https://docs.google.com/document/d/19OlpHVg409hLY6w-_oJVvv-HBWKyO5IJvWdr7DmDJIk/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#6DC466" }} // ここでカラーを指定
          >
            個人情報の取り扱い
          </a>
          に同意します。
        </>
      ),
      required: true,
      backgroundColor: "none"
    },
    
    Text38465765: {
      display: 'none', // "個人情報取り扱い" のテキストを非表示
    },
    Text38465766: {
      display: 'none', // "に同意します。" のテキストを非表示
    },
    SendButton: { 
      as: "input", 
      type: "submit", 
      value: "送信する", 
      children: null // childrenをnullにして無効化
    },
    // メッセージをフォーム内部のボタン直後に表示するための設定
    SendMessage: {
      children: isSubmitted ? "送信しました！" : errorMessage,
      display: "block", // メッセージを表示
      color: isSubmitted ? "green" : "red",
      textAlign: "center", // テキストを中央揃えに
    }
  };

  return (
    <div>
      <ContactUs {...props} overrides={overrides} />
    </div>
  );
}
