import React from 'react';
import { Header, Footer } from '../ui-components';
import ContactUs from '../ui-components/ContactUsOverride';
import './interview.css';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('../', '')] = r(item); });
  return images;
}
const images = importAll(require.context('../img', false, /\.png$/));

const Interview2 = () => {
  return (
    <div>
      <Header
        id="header"
        style={{ zIndex: 999, top: 0, left: 0 }}
        overrides={{
          Header: {
            width: "100%"
          },
          Napoleon: {
            src: images['./napoleone.png']
          },
          Logo: {
            src: images['./logo.png']
          },
          // ここで各ボタンをホームページの特定セクションに遷移させるように変更
          DevelopmentButton: {
            onClick: () => {
              window.location.href = "/#dev-cards"; // 開発実績セクションに遷移
            },
          },
          CompanyButton: {
            onClick: () => {
              window.location.href = "/#office"; // 会社概要セクションに遷移
            },
          },
          AccessButton: {
            onClick: () => {
              window.location.href = "/#access"; // アクセスセクションに遷移
            },
          },
          TopIcon: {
            onClick: () => {
              window.location.href = "/"; // アクセスセクションに遷移
            },
          },
        }}
      />
      <div style={{ maxWidth: "1000px", margin: "40px auto" }}>
        <img
          className="fit-picture"
          src={images['./interview1.png']}
          alt="interview2"
          style={{ width: "100%", height: "auto" }} // 画像の横幅を調整
        />
        <div className="container">
          <p className="date">2024-09-12</p>
          <div className="highlight">
          今回は、「ファストカーボン申込」の開発について、北條様にインタビューさせていただきました。
          </div>
          <div className="text-block">
            株式会社ディエスジャパン 北條様<br></br>
            会社URL: <a href="https://www.dsj.co.jp/" target="_blank" rel="noopener noreferrer" class="url">https://www.dsj.co.jp/</a><br></br>
          </div>
          <div className="highlight">
            ファストカーボン申込システム開発の依頼のきっかけを教えてください。
          </div>
          <div className="text-block">
              今回のファストカーボン申込システムの開発依頼のきっかけは、共通の取引先を通じて紹介を受けたことでした。<br></br>
              過去に依頼していた別の開発プロジェクトでは、開発スピードや期待した内容との不一致が多かったため、より信頼できる開発会社を探していました。<br></br>
              そのタイミングで紹介があり、依頼に至りました。
          </div>
          <div className="highlight">
            依頼してみて、どんな感じでした？
          </div>
          <div className="text-block">
            プロジェクトを依頼してみて、非常にスムーズに進んだと感じました。
            特に印象的だったのは、開発のスピード感とコミュニケーションの効率性です。
            Slackなどのツールを活用し、リアルタイムでやりとりを行うことで、認識の齟齬が少なく、すぐに問題解決ができました。従来のSIerの進め方と異なり、仕様書に固執せず、必要に応じて柔軟に対応してもらえたため、非常にやりやすかったと感じています。
            また、開発中に必要なフィードバックが迅速に反映され、問題なく進めることができました。
          </div>
          <div className="highlight">
            リリースした効果はいかがでしたか？
          </div>
          <div className="text-block">
            リリース後の効果については、まだ具体的な評価を下す段階には至っていません。
            リリースしてからの期間が短く、ユーザー数も少ないため、現時点での効果測定は難しい状況です。
            ただし、求めていた機能はすべて実装され、今後、利用者が増えるにつれて、良い成果が期待できると予測しています。将来的にはポジティブなフィードバックが得られるだろうと感じています。
          </div>
          <div className="highlight">
            弊社の印象を教えてください。
          </div>
          <div className="text-block">
            最初は、ミリオンダウト社が主にゲーム開発を手掛けている企業というイメージが強く、業務システム開発に対する不安がありました。ゲーム開発に精通しているエンジニアが、業務システム開発も問題なく行えるのか疑問に思っていました。
            しかし、実際に開発プロジェクトが進む中で、その不安はすぐに払拭されました。
            システム開発においても十分な技術力を持っており、業務システムとエンターテイメントの分野でバランスよく対応できる会社だという印象に変わりました。
          </div>
          <div className="highlight">
            弊社に求める改善点を教えてください。
          </div>
          <div className="text-block">
            今回のプロジェクトでは、特に大きな問題はなく、非常にスムーズに進行しましたが、改善点として一つ挙げられるのは、ツールの使い方に関する配慮です。
            今回のプロジェクトではSlackを主に使用しましたが、一般的なエンドユーザーにとってSlackは馴染みが薄いツールかもしれません。
            今後、業務システム開発を進める上では、ユーザーが普段使用しているツールに対応した形で、さらに柔軟にコミュニケーションを図ることが求められるかもしれません。
          </div>
          <div className="highlight">
          今後のプロジェクトや新規事業で弊社とどのような形で関わっていきたいですか？
          </div>
          <div className="text-block">
            今後のプロジェクトや新規事業においても、引き続きミリオンダウト社と協力していきたいと考えています。
            具体的には、脱炭素社会の実現に向けた取り組みや、AIを活用したシステム開発など、先進的な技術を活かしたプロジェクトで協力を検討しています。
            また、今後は展示会やウェビナーなど、広報活動やマーケティング面でも連携しながら新しい事業を推進していきたいと考えています。
          </div>
          <div className="highlight">
            ミリオンダウト社について自由にコメントしてください。
          </div>
          <div className="text-block">
            ミリオンダウト社については、ゲーム開発やエンターテイメント分野での実績に加え、業務システム開発においても非常に優れた技術力を持つ企業だと感じました。
            特に、ゲーム開発で培ったUI/UXのスキルや、ユーザーの視点を重視した開発プロセスが業務システムにも活かされており、今後の展開に非常に期待しています。
            どのような新しいサービスや製品を生み出すのか、個人的にも大きな興味を持っています。
            引き続き協力していきたいと考えています。
          </div>
        </div>
      </div>

      <ContactUs
        id="contact-us"
        overrides={{
          ContactUs: {
            width: "100%",
          },
        }}
      />
      <Footer
        overrides={{
          Footer: {
            width: "100%"
          }
        }}
      />
    </div>
  );
};

export default Interview2;
