/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Footer(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: { text: {}, Footer: {} },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: { text: { fontSize: "12px", lineHeight: "12px" }, Footer: {} },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="32px"
      direction="column"
      width="1400px"
      height="175px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="60px 0px 80px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "Footer")}
      {...rest}
    >
      <Text
        fontFamily="Noto Sans JP"
        fontSize="15px"
        fontWeight="300"
        color="rgba(96,96,96,1)"
        lineHeight="15px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="378px"
        height="15px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Copyright © 株式会社ミリオンダウト All rights reserved."
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
