import React, { useEffect } from 'react';
import "@aws-amplify/ui-react/styles.css";
import './App.css';
import './style.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Interview1 from './interview_page/Interview_1';
import Interview2 from './interview_page/Interview_2';

// ページ遷移ごとのGoogle Analyticsトラッキングとスクロール位置をリセットするコンポーネント
function GoogleAnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    // ページ遷移のたびにGoogle Analyticsにページビューを送信
    if (window.gtag) {
      window.gtag('config', 'G-VTZ9Z261KS', {
        page_path: location.pathname,
      });
    }

    window.scrollTo(0, 0);

  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      {/* ページビューをトラッキング */}
      <GoogleAnalyticsTracker />
      <Routes>
        {/* ホームページ */}
        <Route path="/" element={<Home />} />
        {/* インタビューページ */}
        <Route path="/interview/1" element={<Interview1 />} />
        <Route path="/interview/2" element={<Interview2 />} />
      </Routes>
    </Router>
  );
}

export default App;
