/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Trouble(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Title: {},
        TroblePeople: {},
        Circle23862279: {},
        Circle13862280: {},
        "Group 13862278": {},
        Text3862282: {},
        "Frame 23862281": {},
        Bubble3862277: {},
        Circle23862285: {},
        Circle13862286: {},
        "Group 13862284": {},
        Text3862288: {},
        "Frame 23862287": {},
        Bubble3862283: {},
        Circle23862291: {},
        Circle13862292: {},
        "Group 13862290": {},
        Text3862294: {},
        "Frame 23862293": {},
        Bubble3862289: {},
        Circle23862297: {},
        Circle13862298: {},
        "Group 13862296": {},
        Text3862300: {},
        "Frame 23862299": {},
        Bubble3862295: {},
        Circle23862303: {},
        Circle13862304: {},
        "Group 13862302": {},
        Text3862306: {},
        "Frame 23862305": {},
        Bubble3862301: {},
        "Frame 138576403": {},
        "Frame 138576404": {},
        Trouble: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Title: {},
        TroblePeople: { display: "none" },
        Circle23862279: {},
        Circle13862280: {},
        "Group 13862278": { top: "28px" },
        Text3862282: { fontSize: "11px", lineHeight: "16.5px" },
        "Frame 23862281": {
          gap: "0",
          width: "344px",
          top: "0px",
          padding: "11px 10px 11px 10px",
        },
        Bubble3862277: { height: "39px" },
        Circle23862285: {},
        Circle13862286: {},
        "Group 13862284": { top: "28px" },
        Text3862288: { fontSize: "11px", lineHeight: "16.5px" },
        "Frame 23862287": {
          gap: "0",
          width: "344px",
          top: "0px",
          padding: "11px 10px 11px 10px",
        },
        Bubble3862283: { height: "39px" },
        Circle23862291: {},
        Circle13862292: {},
        "Group 13862290": { top: "28px" },
        Text3862294: { fontSize: "11px", lineHeight: "16.5px" },
        "Frame 23862293": {
          gap: "0",
          width: "344px",
          top: "0px",
          padding: "11px 10px 11px 10px",
        },
        Bubble3862289: { height: "39px" },
        Circle23862297: {},
        Circle13862298: {},
        "Group 13862296": { top: "28px" },
        Text3862300: { fontSize: "11px", lineHeight: "16.5px" },
        "Frame 23862299": {
          width: "344px",
          top: "0px",
          padding: "11px 10px 11px 10px",
        },
        Bubble3862295: { height: "39px" },
        Circle23862303: {},
        Circle13862304: {},
        "Group 13862302": { top: "28px" },
        Text3862306: { fontSize: "11px", lineHeight: "16.5px" },
        "Frame 23862305": {
          width: "344px",
          top: "0px",
          padding: "11px 10px 11px 10px",
        },
        Bubble3862301: { height: "39px" },
        "Frame 138576403": { width: "359px" },
        "Frame 138576404": { gap: "10px", width: "359px" },
        Trouble: {
          gap: "20px",
          width: "375px",
          height: "unset",
          padding: "40px 10px 40px 10px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="25px"
      direction="column"
      width="901px"
      height="441px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="40px 20px 40px 20px"
      backgroundColor="rgba(109,196,102,1)"
      display="flex"
      {...getOverrideProps(overrides, "Trouble")}
      {...rest}
    >
      <Text
        fontFamily="Noto Sans JP"
        fontSize="25px"
        fontWeight="700"
        color="rgba(255,255,255,1)"
        lineHeight="36.19999694824219px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="こんなお悩みはありませんか？"
        {...getOverrideProps(overrides, "Title")}
      ></Text>
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 138576404")}
      >
        <Image
          width="91px"
          height="296px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "TroblePeople")}
        ></Image>
        <Flex
          gap="10px"
          direction="column"
          width="508px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 138576403")}
        >
          <View
            width="unset"
            height="50.43px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Bubble3862277")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="19px"
              height="11px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="39.43px"
              left="0px"
              {...getOverrideProps(overrides, "Group 13862278")}
            >
              <Icon
                width="10px"
                height="10px"
                viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
                paths={[
                  {
                    d: "M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="9.09%"
                left="47.37%"
                right="0%"
                {...getOverrideProps(overrides, "Circle23862279")}
              ></Icon>
              <Icon
                width="6px"
                height="6px"
                viewBox={{ minX: 0, minY: 0, width: 6, height: 6 }}
                paths={[
                  {
                    d: "M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="45.45%"
                bottom="0%"
                left="0%"
                right="68.42%"
                {...getOverrideProps(overrides, "Circle13862280")}
              ></Icon>
            </View>
            <Flex
              gap="10px"
              direction="row"
              width="493px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-end"
              position="absolute"
              top="4.43px"
              left="15px"
              borderRadius="10px"
              padding="11px 20px 11px 20px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 23862281")}
            >
              <Text
                fontFamily="Noto Sans JP"
                fontSize="15.5px"
                fontWeight="500"
                color="rgba(96,96,96,1)"
                lineHeight="23.25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="社内にある基幹システムが古くて、開発/保守人員を確保できない"
                {...getOverrideProps(overrides, "Text3862282")}
              ></Text>
            </Flex>
          </View>
          <View
            width="unset"
            height="50.43px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Bubble3862283")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="19px"
              height="11px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="39.43px"
              left="0px"
              {...getOverrideProps(overrides, "Group 13862284")}
            >
              <Icon
                width="10px"
                height="10px"
                viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
                paths={[
                  {
                    d: "M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="9.09%"
                left="47.37%"
                right="0%"
                {...getOverrideProps(overrides, "Circle23862285")}
              ></Icon>
              <Icon
                width="6px"
                height="6px"
                viewBox={{ minX: 0, minY: 0, width: 6, height: 6 }}
                paths={[
                  {
                    d: "M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="45.45%"
                bottom="0%"
                left="0%"
                right="68.42%"
                {...getOverrideProps(overrides, "Circle13862286")}
              ></Icon>
            </View>
            <Flex
              gap="10px"
              direction="row"
              width="493px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-end"
              position="absolute"
              top="4.43px"
              left="15px"
              borderRadius="10px"
              padding="11px 20px 11px 20px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 23862287")}
            >
              <Text
                fontFamily="Noto Sans JP"
                fontSize="16px"
                fontWeight="500"
                color="rgba(96,96,96,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="AIを導入したいんだけど何から手をつけたらいいかわからない"
                {...getOverrideProps(overrides, "Text3862288")}
              ></Text>
            </Flex>
          </View>
          <View
            width="unset"
            height="50.43px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Bubble3862289")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="19px"
              height="11px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="39.43px"
              left="0px"
              {...getOverrideProps(overrides, "Group 13862290")}
            >
              <Icon
                width="10px"
                height="10px"
                viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
                paths={[
                  {
                    d: "M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="9.09%"
                left="47.37%"
                right="0%"
                {...getOverrideProps(overrides, "Circle23862291")}
              ></Icon>
              <Icon
                width="6px"
                height="6px"
                viewBox={{ minX: 0, minY: 0, width: 6, height: 6 }}
                paths={[
                  {
                    d: "M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="45.45%"
                bottom="0%"
                left="0%"
                right="68.42%"
                {...getOverrideProps(overrides, "Circle13862292")}
              ></Icon>
            </View>
            <Flex
              gap="10px"
              direction="row"
              width="493px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-end"
              position="absolute"
              top="4.43px"
              left="15px"
              borderRadius="10px"
              padding="11px 20px 11px 20px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 23862293")}
            >
              <Text
                fontFamily="Noto Sans JP"
                fontSize="16px"
                fontWeight="500"
                color="rgba(96,96,96,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="自社で開発できないし、エンジニアも採用できない"
                {...getOverrideProps(overrides, "Text3862294")}
              ></Text>
            </Flex>
          </View>
          <View
            width="unset"
            height="50.43px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Bubble3862295")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="19px"
              height="11px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="39.43px"
              left="0px"
              {...getOverrideProps(overrides, "Group 13862296")}
            >
              <Icon
                width="10px"
                height="10px"
                viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
                paths={[
                  {
                    d: "M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="9.09%"
                left="47.37%"
                right="0%"
                {...getOverrideProps(overrides, "Circle23862297")}
              ></Icon>
              <Icon
                width="6px"
                height="6px"
                viewBox={{ minX: 0, minY: 0, width: 6, height: 6 }}
                paths={[
                  {
                    d: "M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="45.45%"
                bottom="0%"
                left="0%"
                right="68.42%"
                {...getOverrideProps(overrides, "Circle13862298")}
              ></Icon>
            </View>
            <Flex
              gap="0"
              direction="row"
              width="493px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-end"
              position="absolute"
              top="4.43px"
              left="15px"
              borderRadius="10px"
              padding="11px 20px 11px 20px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 23862299")}
            >
              <Text
                fontFamily="Noto Sans JP"
                fontSize="16px"
                fontWeight="500"
                color="rgba(96,96,96,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="システムが重く使うたびにストレス、UIも使いづらい"
                {...getOverrideProps(overrides, "Text3862300")}
              ></Text>
            </Flex>
          </View>
          <View
            width="unset"
            height="50.43px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Bubble3862301")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="19px"
              height="11px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="39.43px"
              left="0px"
              {...getOverrideProps(overrides, "Group 13862302")}
            >
              <Icon
                width="10px"
                height="10px"
                viewBox={{ minX: 0, minY: 0, width: 10, height: 10 }}
                paths={[
                  {
                    d: "M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="9.09%"
                left="47.37%"
                right="0%"
                {...getOverrideProps(overrides, "Circle23862303")}
              ></Icon>
              <Icon
                width="6px"
                height="6px"
                viewBox={{ minX: 0, minY: 0, width: 6, height: 6 }}
                paths={[
                  {
                    d: "M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="45.45%"
                bottom="0%"
                left="0%"
                right="68.42%"
                {...getOverrideProps(overrides, "Circle13862304")}
              ></Icon>
            </View>
            <Flex
              gap="0"
              direction="row"
              width="493px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-end"
              position="absolute"
              top="4.43px"
              left="15px"
              borderRadius="10px"
              padding="11px 20px 11px 20px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 23862305")}
            >
              <Text
                fontFamily="Noto Sans JP"
                fontSize="16px"
                fontWeight="500"
                color="rgba(96,96,96,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="以前、開発を他社に依頼したがうまくいかなかった"
                {...getOverrideProps(overrides, "Text3862306")}
              ></Text>
            </Flex>
          </View>
        </Flex>
      </Flex>
    </Flex>
  );
}
