import React from 'react';
import { Header, Footer } from '../ui-components';
import ContactUs from '../ui-components/ContactUsOverride';
import './interview.css';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('../', '')] = r(item); });
  return images;
}
const images = importAll(require.context('../img', false, /\.png$/));

const Interview1 = () => {
  return (
    <div>
      <Header
        id="header"
        style={{ zIndex: 999, top: 0, left: 0 }}
        overrides={{
          Header: {
            width: "100%"
          },
          Napoleon: {
            src: images['./napoleone.png']
          },
          Logo: {
            src: images['./logo.png']
          },
          // ここで各ボタンをホームページの特定セクションに遷移させるように変更
          DevelopmentButton: {
            onClick: () => {
              window.location.href = "/#dev-cards"; // 開発実績セクションに遷移
            },
          },
          CompanyButton: {
            onClick: () => {
              window.location.href = "/#office"; // 会社概要セクションに遷移
            },
          },
          AccessButton: {
            onClick: () => {
              window.location.href = "/#access"; // アクセスセクションに遷移
            },
          },
          TopIcon: {
            onClick: () => {
              window.location.href = "/"; // アクセスセクションに遷移
            },
          },
        }}
      />
      <div style={{ maxWidth: "1000px", margin: "40px auto" }}>
        <img
          className="fit-picture"
          src={images['./interview2.png']}
          alt="interview1"
          style={{ width: "100%", height: "auto" }} // 画像の横幅を調整
        />
        <div className="container">
          <p className="date">2024-03-29</p>
          <div className="highlight">
            今回は、「みんコレ！」の開発から事業売却まで(2016〜2022)について、弊社代表の赤星が医師の野田先生にインタビューさせていただきました。
          </div>
          <div className="text-block">
            株式会社メンタルHR 代表取締役 医師・野田北斗先生<br></br>
            会社URL: <a href="https://www.mentalhr.jp" target="_blank" rel="noopener noreferrer" class="url">https://www.mentalhr.jp</a><br></br>
            医師国家試験当日採点アプリ「みんコレ！」事業をメドピアに事業売却。<br></br>
            精神科医、産業医として活動しながら、シリアルアントレプレナーとして、「月曜日が待ち遠しい世界」の実現を目指して事業立ち上げを準備中です。
          </div>
          <div className="highlight">
            みんコレ！の依頼のきっかけを教えてください。
          </div>
          <div className="text-block">
            <div className="name-orange">野田先生：</div>
            みんコレ！は、医師国家試験の受験生向けのサービスです。<br></br>
            実は医学生時代にこれを思いついたんです。<br></br>
            最初は、素人ながら、知人の手を借りて開発を進めていました。<br></br>
            そして、本格的にビジネスとして取り組もうという段階になり、開発を依頼する企業を探し始めました。<br></br>
            医療の知識はあっても、システム開発には詳しくなかったので、中学時代の友人である赤星に相談したんです。<br></br>
            すると、二つ返事で『大丈夫だよ』と返事をもらえて、非常に心強かったので、ミリオンダウト社に開発を依頼することに決めました。
          </div>
          <div className="highlight">
              依頼してみて、どんな感じでした？
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
              非常に良かったです。<br></br>
              お互い知り合いだったのもありましたが、形式的な資料なしでも意図が伝わりました。<br></br>
              頭の中にははっきりシステムのイメージと参考になる資料がありましたが、誰でもわかる形式の資料はありませんでした。<br></br>
              そのため、時間をかけて詳細を説明して、齟齬を取り除く作業をしました。<br></br>
              大手だと、このような依頼の仕方ではいけないような気がします。
          </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
                  実は、そういうお客様は多くて、「こんなものが作りたい」というぼんやりとしたお話をいただいてから、細かく確認しながら、弊社主導で仕様を詰めます。
              </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  そうなんですね。システムについて詳しくなくても、頭の中で動くものが想像できてるのであれば、おそらくミリオンダウトとやるのは非常にやりやすいですね。
              </div>
          <div className="highlight">
              みんコレ！の説明お願いします。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  以前は、試験後に予備校の講師が問題を解いて正解を発表することが主流だったので、採点に時間がかかっていました。医師国家試験は、医学部生にとって、人生に関わる極めて重要なイベントですが、その間、気が気でない状況になります。<br></br>
                  そこで、スマホを使って、受験生同士で多数決投票すれば速やかに採点が可能になると気づきました。<br></br>
                  そのアイデアをシステム化したものが、みんコレ！です。
              </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
              受験生同士で多数決投票をする機能以外の開発は何がありましたか？
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
              質問掲示板と問題出題機能ですね。<br></br>
              みんコレ！は本来、試験当日のみ使用されるシステムですが、それだけでは不十分だと考えがあり、悩みました。<br></br>
              せっかく過去問を蓄積しているのであれば、データを学習に活用してもらうところまでやるべきだろうっていう構想がありました。<br></br>
              つまり、過去問に対して「みんなで議論できる掲示板」と「過去問を演習できるサイト」そして「採点システム」を全部組み合わせたんです。
          </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
              確か、４ヶ月の納期でシステムのご依頼をいただきましたが、３ヶ月で開発を完了しました。<br></br>
              当初の予定よりも１ヶ月早く開発が完了したため、その期間を活用し、外部API連携を実装しました。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
              そうでしたね。  
          </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
              みんコレ！は１発勝負のリリースだったじゃないですか。当日ちゃんと動いて、本当に良かったです。<br></br>
              １発勝負のプロダクトは、事故があると大変なので。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
              リリース当日のトラブルシューティングは絶対必要ですね。
          </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
              そうですね。このサービスは、いきなりアクセス数が上がるので。
          </div>
          <div className="highlight">
              みんコレ！でリリースした効果はいかがでしたか？
          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              みんコレ！が使われることはすでに実証済みだったので、想定通りのいい反響でした。<br></br>
              <br></br>
              開発は最初に一気にやって管理画面も作ってもらったので、その後、エンジニアなしでも運用できるんじゃないかなって思ってたんですが、細かいことをしたい時に、システムに詳しい人が必要でした。<br></br>
              そこで、CTO採用を思い付き、赤星にも面談に同席いただき、お手伝いしてもらいました。
          </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              野田先生が募集のツイートをしたら、すぐ人が集まりましたよね。医師かつエンジニア。<br></br>
              普通は、なかなか簡単には集まらないですよ。<br></br>
              多分、医者でそうやって創業する人は珍しかったので、集まりやすかったんでしょうね。<br></br>
              多数の医者が事業をしていたら、なかなか人員の獲得は厳しいと思う。<br></br>
              あとは、医者は医者以外とあんまり組みたくないのかもしれませんね。
          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              運も良かったし、タイミングも良かったし、環境もよかったですね。<br></br>
              同席で言うと、赤星には顧客を紹介してもらい、営業も同席してクローズまでサポートしてもらいました。
          </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              顧客の開拓が急務と思い、私の前職の社長に相談して、紹介してもらった顧客を野田先生にお繋ぎしました。流れで同席させてもらいました。結果につながって良かったです。
          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              最終的に、赤星にはM&Aの事業売却にも株主として同席してもらいました。
          </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              詳しい内容はNDAがあるんで詳しくは言えないですけど、あのM&Aは良かったんでしょうか？

          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              良かったと思います。残念だけど、当初描いてたような成長路線はもう見えておらず、どこかで整理した方がいい状態だったので、多分1番いいタイミングで買い手が見つかってるとは思っています。<br></br>
              もっと良い路線があったのかもしれませんが、結果から見て、あの時点の判断としては良かったと思います。

          </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              私が同席してお役に立てましたか？

          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              はい。私のポリシーとしては、1人で判断すると非常にミスが多くなると思っています。<br></br>
              例えば１人のミスする確率が５パーセントぐらいあるとすれば、2人になった途端に一気に下がります。<br></br>
              だから私は、自分だけで交渉はやらないようにしています。<br></br>
              その上で、M&Aは秘匿性が強く、かつ私と利害関係が共有できる人しか同席できないため、赤星にやってもらうしかなかった。<br></br>
              しかも赤星はその立場をちゃんとわきまえてる人で、余計なことはやらず、交渉する上で必要なことだけやってくれるので、すごくやりやすかったです。

          </div>
          <div className="highlight">
              弊社の印象を教えてください。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
              大雑把に言えば、ちゃんとしていることですね。<br></br>
              私が１番いいと思ってるのは信用を積もうとしてることですね。<br></br>
              すごくいいなと思っていて。<br></br>
              この人たちが言ってることや、やってることは信じて大丈夫っていう安心感がありますね。<br></br>
              ただし、その基準以外で物事を話そうとすると全く通用しないので、やりやすい人とやりづらい人がいると思います。
          </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
              「信用を積む」以外の基準って普通の人はどういうことを重視しているのでしょうか？
          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              かっこいいとか？
          </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              かっこいい。あ〜、オフィスがかっこいいということですね。<br></br>
              そういうのは弊社は全くないですね。オンボロのオフィスです（笑）山手線沿線徒歩５分なのに坪単価7500円以下の築50年超です。<br></br>
              <br></br>
              かっこよさとかにこだわりがないのはその通りで、当時からあんまり見てくれを気にしたり、体裁を保とうとしたりしてないかもしれません。<br></br>
              弊社が顧客に信用が積めればなんでもいいと思ってます。<br></br>
              法と大衆が許す手段を使う限り、あとは信用していただくためにできることは何でもやりたいと思ってます。<br></br>
              社員に対しても基本的に仕事前と後で信用が増していれば何でもOKと伝えています。<br></br>
              信用が積めないような仕事は、別の方法を提案するようにしています。<br></br>
              <br></br>
              つい最近の話ですが、クライアントから相談があり、よくよく聞いてみると、システムが複雑じゃなくて、ホームページを作るだけで良さそうな依頼がありました。ただ、我々はとりわけデザインが強いという会社ではないため、ホームページ作成が得意なウェブデザイナーに依頼した方が、コストも時間も節約できることを伝えました。短期的にはご依頼を受けた方が経済的に得ですが、長期的には信頼を積み上げた方が得だと思っています。<br></br>
              <br></br>
              相手の期待より下になるようなことはやらないようにしているっていうことですかね。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  普通の人は短期的でもプラスのことはやろうと考えるんですが、やらないという選択をすることに対してポジティブなのがいいんですね。
              </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
                  そうですね。でも、単にできないと断ることもしません。先の話のように、もっといい手段があればそれを提案するように心がけています。
              </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  ミリオンダウト社の印象は、多くの企業がちゃんとしようと思ってるところではないところでちゃんとしていて、それは特に信用を積むっていうことを軸に置いてるから。そういう意味では、ぱっと見ちゃんとしていないように見えますが(笑）、やることは信用して大丈夫です。そういう企業です。
              </div>
          <div className="highlight">
              弊社に求める改善点を教えてください。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  本質的に意味のあることを行いたいというスタンスは理解していますが、こちらが形式的に付き合ってほしい作業があっても、それに付き合わないことですね。<br></br>
                  結構、徹底して付き合わない時があります。<br></br>
                  それは改善して欲しいなと思います。<br></br>
                  ただ、譲歩できる範囲がだんだん広がってるのかなっていう印象もあります。
              </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
                  形式的なことを具体化するとなんですか？
              </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  別にハンコ押さなくてもサインにさせてくればいいんじゃないって時とか。
              </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              その節は失礼しました。僕も未熟でした。今は、大丈夫です。ちゃんとハンコも押します。価値になりにくい形式的な仕事は今も好みませんが、それが顧客からの信頼の向上に貢献するならやる所存です。
          </div>
          <div className="highlight">後のプロジェクトや新規事業で弊社とどのような形で関わっていきたいですか。
          </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  現時点での弊社の方針として、プロトタイプの開発は外部委託ではなく、社内リソースを活用して対応することを考えております。<br></br>
                  現在、赤星には資金調達を支援してもらっています。合わせて、生成AIを活用したPoCをご依頼し、一定の成果を出していただきました。<br></br>
                  <br></br>
                  資金調達がある程度目処が立ったら、またご依頼する可能性があります。
              </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
                  外注ではなく、内部でプロトタイプを開発するということなので、少しアドバイスをさせていただくと、生成系AIの実験については、野田先生がマスターすることが非常に良い手段だと考えています。
              </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  何度か同じアドバイスを受けており、私もその点には同じ意見です。でも、実際に行動に移すまでのハードルを少し感じています。<br></br>
                  <br></br>
                  システム開発は頭の中でイメージしてることをアルゴリズムに置き換え、パズルのように組み合わせることだと思うので、やり始めれば多分できると思います。<br></br>
                  でも、生成AIの開発環境を整える部分については、具体的なイメージができないんです。<br></br>
                  具体的には、想像した出力と実際に出力されたものに大きな乖離があって、イメージ通りに出力するための入力の仕方が分かりません。
              </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
                  それの具体化がプロトタイプのコアなんだと思います。環境を整えるということについては、処方箋があります。<br></br>
                  PoCを通して、ある程度のあたりはついてると思うので、まずは自分で少しずつ「これならできるかな？」と試みることが大切です。とにかく生成AIにたくさん触れてみてください。<br></br>
                  そうすると、ある時、特定のプロンプトを入力して当たりを引く時が来るんですよ。そのガチャを引き続ける楽しみがわかると、習慣になるので楽です。<br></br>
                  <br></br>
                  処方箋は、スマホを開いて、ワンタップでChatGPTなどを開けるように設定しておくことです。<br></br>
                  <br></br>   
                  おすすめは、「GPTs」という自分用にカスタマイズしたChatGPTをめちゃくちゃ簡単に作ることができるサービスです。ぜひ試してみてください。<br></br>
                  <br></br>
                  例えば医療や行政文書、論文を読みたいときに、全文を読むのは大変なので、PDFにして「GPTs」に入力し、質問をするとPDFを参考に回答をしてくれます。<br></br>
                  iPhoneであれば、Safariからホーム画面に追加しておくと、ワンタップで「GPTs」が直接開くように設定できます。<br></br>
                  そうすると、ちょっとした空き時間で簡単に利用できます。<br></br>
                  日常的に使用することで、プロトタイプのコストを抑えられますし、感覚も養えるのでどう作れば良いのかがわかってくると思います。<br></br>
                  せっかくなので、特典として「GPTs」で、最近私が作った「多重人格」を野田先生にプレゼントします。<br></br>
              </div>
          <div className="text-block">
              <div className="name-orange">野田先生：</div>
                  「多重人格」?
              </div>
          <div className="text-block">
              <div className="name-green">赤星：</div>
              「多重人格」はGPTの性能をめちゃくちゃ効率よく引き出せます。これでぜひ内部のプロトタイプ開発を成功させてください。<br></br>
              <br></br>
              (多重人格については社外秘なので、詳しくは弊社にご連絡ください。2023.4.3現在はclaudeをおすすめすることが多いです）
          </div>
          <div className="highlight">
              ミリオンダウト社について自由にコメントしてください。
          </div>
          <div className="text-block">
          <div className="name-orange">野田先生：</div>
              ミリオンダウト社の人たちはとてもゲームが強いので、たまに遊びたいです。
          </div>
          <div className="text-block">
          <div className="name-green">赤星：</div>
              先日遊びに来てくれましたね。いつでもきてください。そういえば、弊社から独立した元社員のカードゲーム会とかにたまに行ってますよね。<br></br>
              <br></br>
              ありがとうございました。
          </div>
        </div>
      </div>

      <ContactUs
        id="contact-us"
        overrides={{
          ContactUs: {
            width: "100%",
          },
        }}
      />
      <Footer
        overrides={{
          Footer: {
            width: "100%"
          }
        }}
      />
    </div>
  );
};

export default Interview1;
