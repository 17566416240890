import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './Home.css';

import {
  Header,
  DevCards,
  Footer,
  Office,
  Trouble,
  Introduction,
  Access
} from './ui-components';
import CardList from './ui-components/CardList';
import ContactUs from './ui-components/ContactUsOverride';
import Carousel from './ui-components/Carousel';
import ModifyIntroduction from './ui-components/ModifyIntroduction';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const images = importAll(require.context('./img', false, /\.png$/));

function Home() {
  const [columns, setColumns] = useState(1);

  const handleTopIconClick = () => {
    if (window.location.pathname === "/") {
      // フラグメント識別子を削除
      window.history.replaceState(null, "", window.location.pathname);
      window.location.reload(); // ホームページならリロード
    }
  };

  // ハッシュスクロール処理の定義
  const handleHashScroll = () => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  };
  // 画面幅に応じた列数を設定
  useEffect(() => {
    // 初期読み込み時にページのトップにスクロール
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    } else {
      handleHashScroll(); // ハッシュがある場合、その要素にスクロール
    }
      
    // 初回ページロード時にスクロール処理を行う
    handleHashScroll();
  
    // 画面幅のチェック処理
    const checkScreenWidth = () => {
      const width = window.innerWidth;
  
      if (width >= 1339) {
        setColumns(4); // 4列以上
      } else if (width >= 1042) {
        setColumns(3); // 3列
      } else if (width >= 716) {
        setColumns(2); // 2列
      } else {
        setColumns(1); // 1列
      }
    };
  
    // 初回チェック
    checkScreenWidth();
  
    // リサイズ時のチェック
    window.addEventListener("resize", checkScreenWidth);
  
    // ハッシュが変わるたびにスクロール処理を再度行う
    window.addEventListener('hashchange', handleHashScroll);
  
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
      window.removeEventListener('hashchange', handleHashScroll);
    };
  }, []);
  

  return (
    <div className="home-page" bgcolor="#FFFFFF">
      <Header
        id="header"
        style={{ zIndex: 999, top: 0, left: 0 }}
        overrides={{
          Header: {
            width: "100%"
          },
          Napoleon: {
            src: images['napoleone.png']
          },
          Logo: {
            src: images['logo.png']
          },
          TopIcon: {
            onClick: handleTopIconClick,
          }
        }}
      />
      <div className="image-container">
        <img
          src={images['top.png']}
          className="responsive-image"
          alt="top"
        />
      </div>
      <DevCards
        id="dev-cards"
        overrides={{
          DevCards: {
            width: "100%",
          },
          Cards: {
            children: <CardList columns={columns} />,
          },
        }}
      />
      <Carousel />
      <Trouble
        id="trouble"
        overrides={{
          Trouble: {
            width: "100%"
          },
          TroblePeople: {
            src: images['troblePeople.png']
          }
        }}
      />
      <ModifyIntroduction />
      <Office
        id="office"
        overrides={{
          Office: {
            width: "100%"
          }
        }}
      />
      <Access
        id="access"
        overrides={{
          Access: {
            width: "100%",
          }
        }}
      />
      <div className="maps-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12954.539251588969!2d139.7693534!3d35.7351988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188dd9a3ffc8cb%3A0x5e32eedf1f251d8e!2z5qCq5byP5Lya56S-44Of44Oq44Kq44Oz44OA44Km44OI!5e0!3m2!1sja!2sjp!4v1727261175300!5m2!1sja!2sjp"
          className="google-map"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <ContactUs
        id="contact-us"
        overrides={{
          ContactUs: {
            width: "100%",
          },
        }}
      />
      <Footer
        id="footer"
        overrides={{
          Footer: {
            width: "100%",
          }
        }}
      />
    </div>
  );
}

export default Home;
