/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Divider, Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Office(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Title38526020: {},
        Title38526021: {},
        Content38526029: {},
        Text38526090: {},
        Divider38526150: {},
        OfficeName: {},
        Title38526099: {},
        Content38526100: {},
        Text38526098: {},
        Divider38526152: {},
        Adress: {},
        Title38526105: {},
        Content38526106: {},
        Text38526104: {},
        Divider38526154: {},
        Time: {},
        Title38526111: {},
        Content38526112: {},
        Text38526110: {},
        Divider38526156: {},
        Day: {},
        Title38526117: {},
        Content38526118: {},
        Text38526116: {},
        Divider38526158: {},
        Phone: {},
        Title38526123: {},
        Content38526124: {},
        Text38526122: {},
        Divider38526160: {},
        Born: {},
        Title38526129: {},
        Content38526130: {},
        Text38526128: {},
        Divider38526162: {},
        Business: {},
        Content38526133: {},
        Office: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Title38526020: {},
        Title38526021: { fontSize: "14px", lineHeight: "21px" },
        Content38526029: { fontSize: "14px", lineHeight: "21px" },
        Text38526090: {
          gap: "5px",
          direction: "column",
          height: "57px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526150: {},
        OfficeName: { height: "58px" },
        Title38526099: { fontSize: "14px", lineHeight: "21px" },
        Content38526100: { fontSize: "12px", lineHeight: "18px" },
        Text38526098: {
          gap: "5px",
          direction: "column",
          height: "78px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526152: {},
        Adress: { height: "79px" },
        Title38526105: { fontSize: "14px", lineHeight: "21px" },
        Content38526106: { fontSize: "14px", lineHeight: "21px" },
        Text38526104: {
          gap: "5px",
          direction: "column",
          height: "57px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526154: {},
        Time: { height: "58px" },
        Title38526111: { fontSize: "14px", lineHeight: "21px" },
        Content38526112: { fontSize: "14px", lineHeight: "21px" },
        Text38526110: {
          gap: "5px",
          direction: "column",
          height: "57px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526156: {},
        Day: { height: "58px" },
        Title38526117: { fontSize: "14px", lineHeight: "21px" },
        Content38526118: { fontSize: "14px", lineHeight: "21px" },
        Text38526116: {
          gap: "5px",
          direction: "column",
          height: "57px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526158: {},
        Phone: { height: "58px" },
        Title38526123: { fontSize: "14px", lineHeight: "21px" },
        Content38526124: { fontSize: "14px", lineHeight: "21px" },
        Text38526122: {
          gap: "5px",
          direction: "column",
          height: "57px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526160: {},
        Born: { height: "58px" },
        Title38526129: { fontSize: "14px", lineHeight: "21px" },
        Content38526130: { fontSize: "14px", lineHeight: "21px" },
        Text38526128: {
          gap: "5px",
          direction: "column",
          width: "unset",
          height: "78px",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
          alignSelf: "stretch",
        },
        Divider38526162: {},
        Business: { height: "79px" },
        Content38526133: { justifyContent: "flex-end", alignSelf: "stretch" },
        Office: { width: "375px", height: "667px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="768px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="40px 20px 40px 20px"
      backgroundColor="rgba(249,249,249,1)"
      display="flex"
      {...getOverrideProps(overrides, "Office")}
      {...rest}
    >
      <Text
        fontFamily="Noto Sans JP"
        fontSize="25px"
        fontWeight="700"
        color="rgba(41,41,41,1)"
        lineHeight="36.19999694824219px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="会社概要"
        {...getOverrideProps(overrides, "Title38526020")}
      ></Text>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Content38526133")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "OfficeName")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526090")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="会社名"
              {...getOverrideProps(overrides, "Title38526021")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="株式会社ミリオンダウト"
              {...getOverrideProps(overrides, "Content38526029")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526150")}
          ></Divider>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Adress")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526098")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="住所"
              {...getOverrideProps(overrides, "Title38526099")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="〒116-0013&#xA;東京都荒川区西日暮里6-44-3 西日暮里シティーハイツ1F"
              {...getOverrideProps(overrides, "Content38526100")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526152")}
          ></Divider>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Time")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526104")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="営業時間"
              {...getOverrideProps(overrides, "Title38526105")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="10:00~19:00"
              {...getOverrideProps(overrides, "Content38526106")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526154")}
          ></Divider>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Day")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526110")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="定休日"
              {...getOverrideProps(overrides, "Title38526111")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="土曜・日曜・祝日"
              {...getOverrideProps(overrides, "Content38526112")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526156")}
          ></Divider>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Phone")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526116")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="電話番号"
              {...getOverrideProps(overrides, "Title38526117")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="03-4296-9522"
              {...getOverrideProps(overrides, "Content38526118")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526158")}
          ></Divider>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Born")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526122")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="設立"
              {...getOverrideProps(overrides, "Title38526123")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="2014年11月"
              {...getOverrideProps(overrides, "Content38526124")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526160")}
          ></Divider>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Business")}
        >
          <Flex
            gap="0"
            direction="row"
            width="600px"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "Text38526128")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="500"
              color="rgba(41,41,41,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="110px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="事業内容"
              {...getOverrideProps(overrides, "Title38526129")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="16px"
              fontWeight="400"
              color="rgba(96,96,96,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="生成AIのPoC&#xA;業務システムの開発"
              {...getOverrideProps(overrides, "Content38526130")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38526162")}
          ></Divider>
        </Flex>
      </Flex>
    </Flex>
  );
}
