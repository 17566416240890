// CardList.js
import React, { useState, useEffect } from "react";
import { Flex, Image, Text, Button } from "@aws-amplify/ui-react";

// 画像
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { 
    images[item.replace('./', '')] = r(item); 
  });
  return images;
}

const images = importAll(require.context('../img/dev', false, /\.png$/));

// カードデータ
const cardData = [
  {
    title: "MillionApps",
    description: "𝕏で話題になり100万インプレッション超え！ユーザーがほしいWebアプリについて書くだけで、ChatGPTを使って約30秒でアプリを生成して公開するサービス",
    image: images['1.png']
  },
  {
    title: "株式会社集英社様",
    description: "集英社様の「少年ジャンプ+」のキャラがかわいいドット絵になって大活躍！AIによるオートバトルゲーム。自動生成テストの仕組みを導入し、工数を削減。",
    image: images['2.png']
  },
  {
    title: "株式会社Why So Serious様",
    description: "株式会社Why So Serious様とのお取り組みです。「東方ドールドラフト」は、人形となった「東方Project」キャラクターたちを取り合ってチームを作って戦わせる！",
    image: images['3.png']
  },
  {
    title: "株式会社アカツキ様",
    description: "テイルズオブリンクの開発およびデータ分析",
    image: images['4.png']
  },
  {
    title: "株式会社ADXL様",
    description: "BtoBマッチングサービスの新規開発をサポート。",
    image: images['5.png']
  },
  {
    title: "ミリオンダウト",
    description: "大富豪とダウトを合わせた対戦ゲームです。将棋や囲碁のプロ棋士、上場企業の経営者、メジャーアーティスト等の各界の著名人がプレイしています。",
    image: images['6.png']
  },
  {
    title: "AI系大手上場企業様",
    description: "弊社が創業間もない頃から、インフラ、クライアント、サーバーの設計開発を行っています。",
    image: images['7.png']
  },
  {
    title: "株式会社ディエスジャパン様",
    description: "CO2排出量を可視化するファストカーボン・ファストカーボン+の、アカウント申込サービスの開発から運用までをサポート",
    image: images['8.png']
  },
  {
    title: "株式会社メンタルHR様",
    description: "サービスの開発、営業、採用、事業売却までサポート。新規事業の生成AIのPoCをサポート。",
    image: images['9.png']
  },
  {
    title: "Party",
    description: "あなたの友達との遊びを提案する、完全招待制サービス",
    image: images['10.png']
  },
  {
    title: "Vivle",
    description: "クリエイターが作品を投稿することができ、ユーザーはその作品を売買できます。",
    image: images['11.png']
  },
  {
    title: "クロノガーディアン",
    description: "武器をぐるぐる回してモンスターを撃退し、あなたの時計を守ろう！スマートウォッチの円形の画面を活かしたアクションゲーム！",
    image: images['12.png']
  }
];

const CardList = ({ columns }) => {
  const [showAll, setShowAll] = useState(false);

  // 状態が更新された際にスクロール処理を行う
  useEffect(() => {
    if (!showAll) {
      // 「閉じる」がクリックされ、カードが閉じられた後にスクロール
      const buttonElement = document.getElementById("more-button");
      if (buttonElement) {
        const rect = buttonElement.getBoundingClientRect();
        const scrollPosition = window.pageYOffset + rect.top - 400; // 100px上に移動
  
        window.scrollTo({
          top: scrollPosition,
          behavior: "instant",
        });
      }
    }
  }, [showAll]);  

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };
  
  // カード表示ロジック
  let displayedCards;
  if (columns >= 4) {
    displayedCards = cardData;
  } else if (columns === 3) {
    displayedCards = showAll ? cardData : cardData.slice(0, 6);
  } else if (columns === 2) {
    displayedCards = showAll ? cardData : cardData.slice(0, 4);
  } else {
    displayedCards = showAll ? cardData : cardData.slice(0, 2);
  }

  // カードのコンテンツ
  const cardComponents = displayedCards.map((card, index) => (
    <Flex
      key={index}
      gap="5px"
      direction="column"
      width="310px"
      justifyContent="flex-start"
      alignItems="center"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 10px 0px"
      backgroundColor="rgba(255,255,255,1)"
      border="0.1px solid #dedede"
    >
      <Image
        width="unset"
        height="180px"
        objectFit="cover"
        src={card.image}
      ></Image>
      <Flex gap="5px" direction="column" alignItems="center" padding="0px 10px">
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="700"
          color="rgba(96,96,96,1)"
          lineHeight="24px"
          textAlign="center"
          children={card.title}
        ></Text>
        <Text
          fontFamily="Noto Sans JP"
          fontSize="14px"
          fontWeight="400"
          color="rgba(96,96,96,1)"
          lineHeight="25.19999885559082px"
          textAlign="center"
          height="126px"
          style={{ whiteSpace: "pre-line" }}
          children={card.description}
        ></Text>
      </Flex>
    </Flex>
  ));

  return (
    <div>
      {/* カードを中央揃えするためのFlexコンテナ */}
      <Flex
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center", // 中央揃え
        }}
      >
        {cardComponents}
      </Flex>
      {columns <= 3 && (
        <Button
          id="more-button"
          width="unset"
          height="unset"
          borderRadius="30px"
          shrink="0"
          backgroundColor="rgba(109,196,102,1)"
          size="default"
          isDisabled={false}
          variation="primary"
          padding="10px 20px"
          border="none"
          boxShadow="none"
          margin="30px auto"
          display="flex"
          onClick={handleToggleShowAll}
        >
          {showAll ? "閉じる" : "もっと見る"}
        </Button>
      )}
    </div>
  );
};

export default CardList;