/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Introduction(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        TextOrange: {},
        Component1: {},
        Title38617275: {},
        Text38617276: {},
        Frame38617274: {},
        Component38617266: {},
        Component2: {},
        Title38617280: {},
        Text38617281: {},
        Frame38617279: {},
        "Component2-1": {},
        Component38617277: {},
        Component3: {},
        Title38617285: {},
        Text38617286: {},
        Frame38617284: {},
        Component38617282: {},
        Introduction5916885: {},
        Introduction: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        TextOrange: {
          fontSize: "13px",
          lineHeight: "23.399999618530273px",
          width: "unset",
          alignSelf: "stretch",
          children:
            "\u30DE\u30C3\u30AD\u30F3\u30BC\u30FC\u306E\u30EC\u30DD\u30FC\u30C8\u306B\u3088\u308C\u3070\u3001\u5927\u898F\u6A21\u958B\u767A\u306F\u3001\u4E88\u7B97\u309245%\u3001\u671F\u9593\u30927%\u8D85\u904E\u3057\u306A\u304C\u3089\u3001\u4E88\u6E2C\u3055\u308C\u305F\u4FA1\u5024\u306E56%\u3057\u304B\u63D0\u4F9B\u3057\u307E\u305B\u3093\u3002\u2028\u540C\u30EC\u30DD\u30FC\u30C8\u306B\u3088\u308C\u3070\u3001\u6709\u52B9\u306A\u51E6\u65B9\u7B8B\u306F\u3001\u77ED\u3044\u7D0D\u54C1\u30B5\u30A4\u30AF\u30EB\u3068\u53B3\u683C\u306A\u54C1\u8CEA\u30C1\u30A7\u30C3\u30AF\u3068\u8A00\u308F\u308C\u3066\u3044\u307E\u3059\u3002\u2028\u2028\u307E\u305F\u3001\u56FD\u5185\u306E\u8ABF\u67FB\u306B\u3088\u308C\u3070\u3001\u54C1\u8CEA\u3068\u4E88\u7B97\u3068\u7D0D\u671F\u3092\u3059\u3079\u3066\u6E80\u305F\u3057\u305F\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u306F10%\u3068\u3082\u3044\u308F\u308C\u3066\u3044\u307E\u3059\u3002\u2028\u2028\u5F0A\u793E\u306F\u3001\u5185\u90E8\u958B\u767A\u306EAI\u306B\u3088\u308A\u3001\u77ED\u7D0D\u671F\u306E\u30B5\u30A4\u30AF\u30EB\u3068\u54C1\u8CEA\u4FDD\u8A3C\u3092\u5B9F\u73FE\u3057\u3066\u3044\u307E\u3059\u3002\u2028\u305D\u306E\u7D50\u679C\u3001\u3053\u308C\u307E\u3067\u304A\u53D6\u5F15\u3044\u305F\u3060\u3044\u305F\u3059\u3079\u3066\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u69D8\u304B\u3089\u3001\u7D99\u7D9A\u767A\u6CE8\u3092\u3044\u305F\u3060\u3044\u3066\u304A\u308A\u307E\u3059\u3002",
        },
        Component1: { width: "unset", height: "173.5px", alignSelf: "stretch" },
        Title38617275: {
          fontSize: "20px",
          lineHeight: "28.959999084472656px",
          height: "29px",
        },
        Text38617276: {
          fontSize: "14px",
          lineHeight: "25.19999885559082px",
          children:
            "\u5F0A\u793E\u306F\u300110\u5E74\u4EE5\u4E0A\u306B\u308F\u305F\u308A\u591A\u5C90\u306B\u308F\u305F\u308B\u696D\u754C\u3067\u30B7\u30B9\u30C6\u30E0\u958B\u767A\u306B\u643A\u308F\u3063\u3066\u304D\u305F\u5B9F\u7E3E\u304C\u3042\u308A\u307E\u3059\u3002\n\u5927\u624B\u4E0A\u5834\u4F01\u696D\u69D8\u3092\u4E2D\u5FC3\u306B\u3001\u9577\u671F\u306B\u308F\u305F\u3063\u3066\u304A\u53D6\u5F15\u3057\u3066\u3044\u305F\u3060\u3044\u3066\u3044\u307E\u3059\u3002\n\u3053\u308C\u307E\u3067\u304A\u53D6\u5F15\u3044\u305F\u3060\u3044\u305F\u3059\u3079\u3066\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304B\u3089\u3001\u7D99\u7D9A\u767A\u6CE8\u3092\u3044\u305F\u3060\u3044\u3066\u304A\u308A\u307E\u3059\u3002\n\u6700\u3082\u5F97\u610F\u3068\u3059\u308B\u306E\u306F\u3001\u8981\u4EF6\u304C\u5B9A\u307E\u3063\u3066\u3044\u306A\u3044\u72B6\u614B\u304B\u3089\u6574\u7406\u3057\u3066\u5805\u7262\u306A\u30B7\u30B9\u30C6\u30E0\u3092\u69CB\u7BC9\u3059\u308B\u3053\u3068\u3067\u3059\u3002\n\u7279\u306B\u3001\u96C6\u82F1\u793E\u69D8\u3068\u306E\u304A\u53D6\u308A\u7D44\u307F\u3067\u4F5C\u3063\u305F\u3086\u308B\u3063\u3068\u30B8\u30E3\u30F3\u30D7\uFF0B\u306F\u3001\u898F\u6A21\u306E\u9055\u3044\u306F\u3042\u308A\u307E\u3059\u304C\u3001\u901A\u5E38\u30BD\u30FC\u30B7\u30E3\u30EB\u30B2\u30FC\u30E0\u306E\u958B\u767A\u304C2-3\u5E74\u304B\u304B\u308B\u3068\u3053\u308D\u30924\u30F6\u6708\u3067\u4F01\u753B\u30FB\u958B\u767A\u30FB\u7D0D\u54C1\u3057\u307E\u3057\u305F\u3002",
        },
        Frame38617274: {
          width: "unset",
          alignItems: "center",
          alignSelf: "stretch",
        },
        Component38617266: { direction: "column" },
        Component2: { width: "unset", height: "173.5px", display: "block" },
        Title38617280: { fontSize: "20px", lineHeight: "28.959999084472656px" },
        Text38617281: { fontSize: "14px", lineHeight: "25.19999885559082px" },
        Frame38617279: { width: "unset", alignSelf: "stretch" },
        "Component2-1": {
          width: "unset",
          height: "173.5px",
          display: "none",
          alignSelf: "stretch",
        },
        Component38617277: { direction: "column", alignItems: "flex-start" },
        Component3: { width: "unset", height: "173.5px", alignSelf: "stretch" },
        Title38617285: { fontSize: "20px", lineHeight: "28.959999084472656px" },
        Text38617286: {
          fontSize: "14px",
          lineHeight: "25.19999885559082px",
          width: "unset",
          alignSelf: "stretch",
        },
        Frame38617284: { width: "unset", alignSelf: "stretch" },
        Component38617282: { direction: "column", alignItems: "flex-start" },
        Introduction5916885: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          alignSelf: "stretch",
        },
        Introduction: {
          width: "767px",
          justifyContent: "flex-end",
          padding: "0px 0px 0px 0px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="845px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="40px 20px 40px 20px"
      display="flex"
      {...getOverrideProps(overrides, "Introduction")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="20px 40px 20px 40px"
        display="flex"
        {...getOverrideProps(overrides, "Introduction5916885")}
      >
        <Text
          fontFamily="Noto Sans JP"
          fontSize="15px"
          fontWeight="700"
          color="rgba(255,110,33,1)"
          lineHeight="27px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="745px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="マッキンゼーのレポートによれば、大規模開発は、予算を45%、期間を7%超過しながら、&#xA;予測された価値の56%しか提供しません。&#xA;同レポートによれば、有効な処方箋は、短い納品サイクルと厳格な品質チェックと言われています。&#xA;&#xA;また、国内の調査によれば、品質と予算と納期をすべて満たしたプロジェクトは10%ともいわれています。&#xA;&#xA;弊社は、内部開発のAIにより、短納期のサイクルと品質保証を実現しています。&#xA;その結果、これまでお取引いただいたすべてのクライアント様から、継続発注をいただいております。"
          {...getOverrideProps(overrides, "TextOrange")}
        ></Text>
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Component38617266")}
        >
          <Image
            width="290px"
            height="397px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            {...getOverrideProps(overrides, "Component1")}
          ></Image>
          <Flex
            gap="10px"
            direction="column"
            width="415px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame38617274")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="25px"
              fontWeight="700"
              color="rgba(109,196,101,1)"
              lineHeight="36.19999694824219px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="信頼と実績"
              {...getOverrideProps(overrides, "Title38617275")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="15px"
              fontWeight="500"
              color="rgba(96,96,96,1)"
              lineHeight="27px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              className="introduction-text"
              children="弊社は、10年以上にわたり多岐にわたる業界でシステム開発に携わってきた実績があります。&#x2028;大手上場企業様を中心に、長期にわたってお取引していただいています。&#x2028;これまでお取引いただいたすべてのクライアントから、継続発注をいただいております。&#x2028;最も得意とするのは、要件が定まっていない状態から整理して手早くシステムを構築することです。&#x2028;売れる/使われるシステムの開発に取り組ませていただければと思っています。&#x2028;特に、集英社様とのお取り組みで作ったゆるっとジャンプ＋は、規模の違いはありますが、通常ソーシャルゲームの開発が2-3年かかるところを4ヶ月で企画・開発・納品しました。"
              {...getOverrideProps(overrides, "Text38617276")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Component38617277")}
        >
          <Image
            width="295px"
            height="unset"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            {...getOverrideProps(overrides, "Component2")}
          ></Image>
          <Flex
            gap="10px"
            direction="column"
            width="415px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame38617279")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="25px"
              fontWeight="700"
              color="rgba(109,196,101,1)"
              lineHeight="36.19999694824219px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="実績の秘密"
              {...getOverrideProps(overrides, "Title38617280")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="15px"
              fontWeight="500"
              color="rgba(96,96,96,1)"
              lineHeight="27px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="弊社は40000人に1人の人材を採用しています。&#x2028;自社開発ゲーム「ミリオンダウト」の累計ダウンロード数は40万超になります。&#x2028;その40万人の中からトップランカーの称号である「Jokerクラス」の到達者約500名の中から、さらに厳選した人材を10人、これまで採用してきました。&#x2028;結果として、囲碁のプロ棋士、東大出身のキャリア官僚、Mensa会員からペン回し日本一の人物まで、多様性に富む人材を採用してきました。採用後は、ほぼ全員エンジニアとして活躍しております。&#x2028;ミリオンダウトは、論理的な思考力、不運への耐性、自己責任への理解を強く要求されることを目指してつくられたゲームです。&#x2028;これらの素養は、ロジカルに思考する能力だけでなく、ビジネス上で求められるリスクテイクとリスクマネジメントに必要な能力であると考えています。&#x2028;結果として、納期遅延率0%を実現でき、お客様からの高い評価を頂くことができました。"
              {...getOverrideProps(overrides, "Text38617281")}
            ></Text>
          </Flex>
          <Image
            width="290px"
            height="532px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            {...getOverrideProps(overrides, "Component2-1")}
          ></Image>
        </Flex>
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Component38617282")}
        >
          <Image
            width="290px"
            height="397px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            {...getOverrideProps(overrides, "Component3")}
          ></Image>
          <Flex
            gap="10px"
            direction="column"
            width="415px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame38617284")}
          >
            <Text
              fontFamily="Noto Sans JP"
              fontSize="25px"
              fontWeight="700"
              color="rgba(109,196,101,1)"
              lineHeight="36.19999694824219px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="生成AIへの取り組み"
              {...getOverrideProps(overrides, "Title38617285")}
            ></Text>
            <Text
              fontFamily="Noto Sans JP"
              fontSize="15px"
              fontWeight="500"
              color="rgba(96,96,96,1)"
              lineHeight="27px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="410px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="生成AIのお取り組みにあたり、弊社が重視しているのがUXと利用ガイドラインの制定です。&#x2028;・UXについて&#x2028;多くの人にとって、自由入力欄でAIを活用することに慣れていません。&#x2028;使いやすさを重視することで、凄いけど誰も使わないツールではなく、多くの人に使われることを目指したプロダクトづくりを心がけています。&#x2028;・生成AIの利用ガイドラインについて&#x2028;多くの企業にとって、生成AIのリスクの把握や社内での利用上のガイドラインの設計が急務であると認識しています。&#x2028;他社動向を交えながら、ガイドラインのお手伝いをさせていただきます。"
              {...getOverrideProps(overrides, "Text38617286")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
