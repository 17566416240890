/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import {
  Flex,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Header(props) {
  const { ClassName, id, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Button Icon38475978": {},
        label38475979: {},
        "Button Icon38475980": {},
        DevelopmentButton: {},
        "Button Icon38475982": {},
        label38475983: {},
        "Button Icon38475984": {},
        CompanyButton: {},
        "Button Icon38475986": {},
        label38475987: {},
        "Button Icon38475988": {},
        AccessButton: {},
        "Button Icon38475990": {},
        label38475991: {},
        "Button Icon38475992": {},
        ContactButton: {},
        RightSide: {},
        Logo: {},
        Napoleon: {},
        TopIcon: {},
        LeftSide: {},
        Header: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        "Button Icon38475978": {},
        label38475979: {},
        "Button Icon38475980": {},
        DevelopmentButton: { display: "none" },
        "Button Icon38475982": {},
        label38475983: {},
        "Button Icon38475984": {},
        CompanyButton: { display: "none" },
        "Button Icon38475986": {},
        label38475987: {},
        "Button Icon38475988": {},
        AccessButton: { display: "none" },
        "Button Icon38475990": {},
        label38475991: {},
        "Button Icon38475992": {},
        ContactButton: {},
        RightSide: {},
        Logo: {},
        Napoleon: {},
        TopIcon: {},
        LeftSide: {},
        Header: { width: "375px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const developmentButtonOnClick = useNavigateAction({
    anchor: "dev-cards",
    type: "anchor",
  });
  const companyButtonOnClick = useNavigateAction({
    anchor: "office",
    type: "anchor",
  });
  const accessButtonOnClick = useNavigateAction({
    anchor: "access",
    type: "anchor",
  });
  const contactButtonOnClick = useNavigateAction({
    anchor: "contact-us",
    type: "anchor",
  });
  return (
    <View
      width="973px"
      height="64px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Header")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        position="absolute"
        top="calc(50% - 22.5px - 0.5px)"
        right="19.82px"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "RightSide")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="125px"
          height="45px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="30px"
          padding="8px 16px 8px 16px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          id="development-button"
          onClick={() => {
            developmentButtonOnClick();
          }}
          {...getOverrideProps(overrides, "DevelopmentButton")}
        >
          <View
            width="20px"
            height="20px"
            {...getOverrideProps(overrides, "Button Icon38475978")}
          ></View>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(41,41,41,1)"
            lineHeight="16px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="開発実績"
            {...getOverrideProps(overrides, "label38475979")}
          ></Text>
          <View
            width="20px"
            height="20px"
            {...getOverrideProps(overrides, "Button Icon38475980")}
          ></View>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="125px"
          height="45px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="30px"
          padding="8px 16px 8px 16px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          id="company-button"
          onClick={() => {
            companyButtonOnClick();
          }}
          {...getOverrideProps(overrides, "CompanyButton")}
        >
          <View
            width="20px"
            height="20px"
            {...getOverrideProps(overrides, "Button Icon38475982")}
          ></View>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(41,41,41,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="会社概要"
            {...getOverrideProps(overrides, "label38475983")}
          ></Text>
          <View
            width="20px"
            height="20px"
            {...getOverrideProps(overrides, "Button Icon38475984")}
          ></View>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="125px"
          height="45px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="30px"
          padding="8px 16px 8px 16px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          id="access-button"
          onClick={() => {
            accessButtonOnClick();
          }}
          {...getOverrideProps(overrides, "AccessButton")}
        >
          <View
            width="20px"
            height="20px"
            {...getOverrideProps(overrides, "Button Icon38475986")}
          ></View>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(41,41,41,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="アクセス"
            {...getOverrideProps(overrides, "label38475987")}
          ></Text>
          <View
            width="20px"
            height="20px"
            {...getOverrideProps(overrides, "Button Icon38475988")}
          ></View>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="150px"
          height="45px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="0px SOLID rgba(0,0,0,0)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="30px"
          padding="8px 16px 8px 16px"
          backgroundColor="rgba(109,196,102,1)"
          display="flex"
          id="contact-button"
          onClick={() => {
            contactButtonOnClick();
          }}
          {...getOverrideProps(overrides, "ContactButton")}
        >
          <View
            width="16px"
            height="16px"
            {...getOverrideProps(overrides, "Button Icon38475990")}
          ></View>
          <Text
            fontFamily="Noto Sans JP"
            fontSize="15px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="21.719999313354492px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="お問い合わせ"
            {...getOverrideProps(overrides, "label38475991")}
          ></Text>
          <View
            width="16px"
            height="16px"
            {...getOverrideProps(overrides, "Button Icon38475992")}
          ></View>
        </Flex>
      </Flex>
      <View
        width="91.57px"
        height="44px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="calc(50% - 22px - 0px)"
        left="21px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "LeftSide")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="88px"
          height="44px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          id="top-button"
          {...getOverrideProps(overrides, "TopIcon")}
        >
          <Image
            width="56.57px"
            height="44px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            border="0px SOLID rgba(112,112,112,1)"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "Logo")}
          ></Image>
          <Image
            width="44px"
            height="44px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="44px"
            border="0px SOLID rgba(112,112,112,1)"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "Napoleon")}
          ></Image>
        </View>
      </View>
    </View>
  );
}
