/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function DevCards(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="20px"
      direction="column"
      width="1132px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="40px 20px 40px 20px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "DevCards")}
      {...rest}
    >
      <Text
        fontFamily="Noto Sans JP"
        fontSize="25px"
        fontWeight="700"
        color="rgba(41,41,41,1)"
        lineHeight="36.19999694824219px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="開発実績"
        {...getOverrideProps(overrides, "Title")}
      ></Text>
      <Flex
        gap="15px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 20px 10px 20px"
        {...getOverrideProps(overrides, "Cards")}
      ></Flex>
    </Flex>
  );
}
